/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function ModalCopy({ data, onClose }) {
  dayjs.locale('pt');
  const countReload = useRef(0);
  const [shortUrl, setShortUrl] = useState('');

  const excludeLink = (days) => {
    const now = new Date();
    return now.setDate(now.getDate() + days);
  }

  useEffect(() => {
    if (countReload.current > 0) return;
    axios({
      method: 'post',
      url: 'https://api.short.io/links/public',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'pk_xoWR7E8ozYAMwbVY',
      },
      data: {
        domain: 'bw34.short.gy',
        allowDuplicates: true,
        originalURL: data.mapsLink,
        title: data.title,
        ttl: excludeLink(15)
      }
    }).then(res => setShortUrl(res.data.shortURL)).catch(() => setShortUrl(data.mapsLink));

    countReload.current += 1;
  }, []);

  const text = `
🛵 *Scooter Club Fortaleza* 🛵
*${data.title.toUpperCase()} ${dayjs(data.date).format('DD/MM')} (${dayjs(data.date).locale('pt-br').format('dddd')})*
📍 Ponto de encontro: *${data.meetPoint.trim()}*
⏰ Horário: *${data.hour}* Saída: *${data.departureHour}*
🏁 Destino: *${data.destiny.trim()}*

${shortUrl ? (`🗺️ ${shortUrl}`) : ('Lista ⬇️')}

1.
2.
3.
4. 
5.
6.
7.
8.
9.
10.
11. 
12.
13. 
14.
15.
16.
17.
18.
19.
20.
  `;

  return (
    <Backdrop open>
      <Dialog open>
        <DialogTitle>Lista gerada</DialogTitle>
        <DialogContent>
          <code>
            {text}
          </code>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
          >
            Fechar
          </Button>

          <CopyToClipboard
            text={text}
            onCopy={() => toast.success('Lista copiada!')}
          >
            <Button variant="contained">Copiar</Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    </Backdrop>
  )
}