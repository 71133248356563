import { createTheme } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#EDEDED',
            contrastText: '#000'
        },
        secondary: {
            main: '#444444',
            contrastText: '#fff'
        },
        light: {
            main: '#DA0037',
            contrastText: '#eee',
        },
        dark: {
            main: '#171717',
            contrastText: '#eee',
        },
    },
    typography: {
        fontFamily: 'Nunito, sans-serif',
        h1: {fontFamily: 'Nunito, sans-serif', fontWeight: 800},
        h2: {fontFamily: 'Nunito, sans-serif', fontWeight: 800},
        h3: {fontFamily: 'Nunito, sans-serif', fontWeight: 800},
        h4: {fontFamily: 'Nunito, sans-serif', fontWeight: 800},
        h5: {fontFamily: 'Nunito, sans-serif', fontWeight: 800},
        h6: {fontFamily: 'Nunito, sans-serif', fontWeight: 800},
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    letterSpacing: 1,
                    fontFamily: 'Nunito, sans-serif',
                    fontWeight: 800,
                }
            },
            defaultProps: {
                disableElevation: true
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root': {
                        color: '#fff'
                    },
                    'input::-webkit-calendar-picker-indicator': {
                        filter: 'invert(1)',
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#fff',
                },
                root: {
                    color: '#fff',
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: '#fff',
                    },
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiDialog-paper': {
                        background: '#000',
                    }
                }
            }
        }
    }
})
