import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Stack,
} from '@mui/material';
import ModalCopy from './components/ModalCopy';
import styles from './styles.module.scss'
import logo from './assets/logo.png';
import project from '../package.json';

function App() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    title: '',
    date: '',
    meetPoint: '',
    hour: '',
    departureHour: '',
    destiny: '',
    mapsLink: '',
  });
  const handleFillData = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  const today = new Date().toISOString().split('T')[0];
  const isDesktop = window.screen.width > 1024;

  return (
    <Container>
      <Stack
        component="figure"
        justifyContent="center"
        alignItems="center"
      >
        <img src={logo} alt="Scooter Clube Fortaleza" width="120" height="auto" />
      </Stack>
      <Typography
        component="h1"
        variant="h4"
        textAlign="center"
        gutterBottom
        width="100%"
      >
        Scooter Clube Fortaleza
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        width="100%"
      >
        Gerar lista
      </Typography>
      <Box
        component="form"
        className={styles.formGroup}
        onSubmit={(event) => {
          event.preventDefault();
          setShowModal(true);
        }}
      >
        <TextField 
          label="Título"
          type="text"
          name="title"
          onChange={(event) => handleFillData(event)}
          required
        />
        <TextField
          label="Data"
          type="date"
          name="date"
          inputProps={{ min:today }}
          onChange={(event) => handleFillData(event)}
          focused={isDesktop}
          required
        />
        <TextField
          label="Ponto de encontro"
          type="text"
          name="meetPoint"
          onChange={(event) => handleFillData(event)}
          required
        />
        <TextField
          label="Horário"
          type="time"
          name="hour"
          onChange={(event) => handleFillData(event)}
          focused={isDesktop}
          required
        />
        <TextField
          label="Saída"
          type="time"
          name="departureHour"
          onChange={(event) => handleFillData(event)}
          focused={isDesktop}
          required
        />
        <TextField
          label="Destino"
          type="text"
          name="destiny"
          onChange={(event) => handleFillData(event)}
          required
        />
        <TextField
          label="Link Google Maps"
          type="text"
          name="mapsLink"
          onChange={(event) => handleFillData(event)}
        />
        <Button
          variant="contained"
          type="submit"
          size="large"
        >
          Criar lista
        </Button>
      </Box>
      <code style={{ fontSize: '8px' }}>v{project.version}</code>

      {showModal ? (
        <ModalCopy
          data={data}
          onClose={() => setShowModal(false)}
        />
      ) : null}
      
    </Container>
  );
}

export default App;
